//
// Icon info
//

@mixin icon-info-a {
  a {
    display: block;

    &,
    &:hover {
      color: inherit;
    }
  }
}
